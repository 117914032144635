import React, { createContext, useEffect, useState, useRef } from 'react';
import Keycloak from 'keycloak-js';
import { PwaFeatures, useFeatureFlag } from '../common/http/hooks/feature-flags';
import { useUserDeauthentication } from '../common/http/hooks/user';
import { useQueryClient } from 'react-query';
import { ROUTE_PATH } from '../routing/route-paths';
import useStickyState from '../common/hooks/useStickyState';
import { useNavigate } from 'react-router-dom';
import Spinner from '../common/components/Spinner/Spinner';

interface AuthContextProps {
  keycloak: Keycloak | null;
  authToken: string | null;
  logout: () => void;
  isKeycloakEnabled: string | null;
  onUserAuthenticated: (props: OnUserAuthenticatedProps) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

interface OnUserAuthenticatedProps {
  isListenerRole: boolean;
  isCallerRole: boolean;
  isAdminRole: boolean;
  authToken: string;
}

export const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_IDP_URL!,
  realm: process.env.REACT_APP_KEYCLOAK_IDP_REALM!,
  clientId: process.env.REACT_APP_KEYCLOAK_IDP_CLIENT_ID!,
};

const TOKEN_EXPIRATION_THRESHOLD = 60; // seconds
const TOKEN_CHECK_INTERVAL = 30000; // milliseconds

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const isFirstRun = useRef<boolean>(false);
  const keycloak = useRef<Keycloak | null>(null);
  const [authToken, setAuthToken] = useStickyState('token', null);
  const [refreshToken, setRefreshToken] = useStickyState('refresh_token', null);
  const keycloakFeatureFlag = useFeatureFlag(PwaFeatures.PWA_KEYCLOAK_LOGIN);
  const [isKeycloakEnabled, setIsKeycloakEnabled] = useStickyState('isKeycloakEnabled', null);
  const navigate = useNavigate();
  const deauthenticateUser = useUserDeauthentication();
  const queryClient = useQueryClient();

  const onUserAuthenticated = ({ isListenerRole, isCallerRole, isAdminRole, authToken }: OnUserAuthenticatedProps) => {
    if (!isListenerRole) {
      if (isCallerRole) {
        navigate(ROUTE_PATH.mwaRedirect);
        return;
      } else if (isAdminRole) {
        navigate(ROUTE_PATH.switchboardRedirect);
        return;
      }
    }
    if (authToken) {
      setAuthToken(authToken);
    }
    queryClient.invalidateQueries(['currentUser']);
    navigate(ROUTE_PATH.home.home);
  };

  const logout = () => {
    if (keycloakFeatureFlag.enabled && keycloak) {
      keycloak.current?.logout();
    }
    deauthenticateUser.mutateAsync();
    localStorage.clear();
    setAuthToken(null);
    queryClient.setQueryData(['currentUser'], null);
    queryClient.setQueryData(['me'], null);
  };

  useEffect(() => {
    setIsKeycloakEnabled(keycloakFeatureFlag.enabled ? 'true' : null);
  }, [keycloakFeatureFlag.enabled]);

  useEffect(() => {
    if (isFirstRun.current) return;
    isFirstRun.current = true;
    const initKeycloak = async () => {
      const keycloakInstance: Keycloak = new Keycloak(keycloakConfig);

      keycloakInstance
        .init({
          onLoad: 'check-sso',
        })
        .then((authenticated: boolean) => {
          if (keycloakInstance.token) {
            const isAuthenticating = authToken === null;

            // if authToken is already set (in localstorage), but keycloak.check-sso returns false,
            // then defer to the localstorage token and set it into keycloak
            if (!authenticated && authToken) {
              keycloakInstance.token = authToken;
              keycloakInstance.refreshToken = refreshToken || undefined;
            } else {
              setAuthToken(keycloakInstance.token);
            }

            const isListenerRole = keycloakInstance.hasRealmRole('listener');
            const isCallerRole = keycloakInstance.hasRealmRole('caller');
            const isAdminRole = keycloakInstance.hasRealmRole('administrator');

            isAuthenticating &&
              onUserAuthenticated({ isListenerRole, isCallerRole, isAdminRole, authToken: keycloakInstance.token });
          }
        })
        .catch(() => {
          setAuthToken(null);
        })
        .finally(() => {
          keycloak.current = keycloakInstance;
        });
    };

    initKeycloak();

    // Function to check and refresh the token
    const checkAndRefreshToken = () => {
      if (keycloak.current?.authenticated && keycloak.current.isTokenExpired(TOKEN_EXPIRATION_THRESHOLD)) {
        keycloak.current
          .updateToken(TOKEN_EXPIRATION_THRESHOLD)
          .then((refreshed) => {
            if (refreshed) {
              setAuthToken(keycloak.current?.token ?? null);
              setRefreshToken(keycloak.current?.refreshToken ?? null);
            }
          })
          .catch(() => {
            setAuthToken(null);
          });
      }
    };

    // Set up interval to check token expiration every 30 seconds
    const tokenCheckInterval = setInterval(checkAndRefreshToken, TOKEN_CHECK_INTERVAL);

    // Clean up interval on component unmount
    return () => clearInterval(tokenCheckInterval);
  }, []);

  // keycloak takes longer to intialize than most pages do to load;
  // this was causing pages to render, and then re-render when keycloak was initialized
  // this spinner will prevent that bad UI
  if (isKeycloakEnabled && !keycloak) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider
      value={{ keycloak: keycloak.current, authToken, logout, isKeycloakEnabled, onUserAuthenticated }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
