import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';

import { PeerState, ProfilePhotoStatus } from '../../common/types';
import BackButton from '../CrisisElevation/BackButton';
import { useExperiences } from '../../common/http/hooks/listener-audio';
import Spinner from '../../common/components/Spinner/Spinner';
import { useCurrentUser } from '../../contexts/user-context';
import { Steps } from './PeerApplicationFlow';

interface ApplicationPendingLayoutProps {
  setActiveStep: React.Dispatch<React.SetStateAction<Steps>>;
  setRevisit?: React.Dispatch<React.SetStateAction<boolean>>;
  imageSrc: string;
  headerText: string;
  bodyText: string;
  peerState: PeerState | undefined;
}

const ApplicationPendingLayout: React.FC<ApplicationPendingLayoutProps> = ({
  setActiveStep,
  setRevisit,
  imageSrc,
  headerText,
  bodyText,
  peerState,
}) => {
  const user = useCurrentUser();
  const experiencesQuery = useExperiences();
  if (!experiencesQuery.data) return <Spinner />;

  const getStatusOptions = () => {
    const errorOptions = { bgcolor: 'error.main', color: 'error.contrastText' };

    if (user.listenerRole?.proposedProfilePhoto?.status === ProfilePhotoStatus.rejected || user.displayNameRejected)
      return { status: 'REVISIT PROFILE', to: Steps.Profile, ...errorOptions };
    if (experiencesQuery.data && experiencesQuery.data.length > 0 && experiencesQuery.data[0].quality === 'revisit')
      return { status: 'REVISIT EXPERIENCE', to: Steps.Experience, ...errorOptions };

    if (peerState === PeerState.onboarding_peer) {
      return { status: 'PENDING', bgcolor: 'warning.main', to: null, color: 'warning.contrastText' };
    } else if (peerState == PeerState.rejected_peer) {
      return { status: 'REJECTED', to: null, ...errorOptions };
    } else {
      return { status: 'ERROR, CONTACT HELP', to: null, ...errorOptions };
    }
  };

  const statusOptions = getStatusOptions();
  return (
    <>
      {statusOptions.status.includes('REVISIT') && (
        <>
          <Box mt={2} />
          <BackButton
            onClick={() => {
              statusOptions.to && setActiveStep(statusOptions.to);
              setRevisit && setRevisit(true);
            }}
          >
            Revisit
          </BackButton>
        </>
      )}
      <Box mt={4} />
      <Box display="flex" justifyContent="center">
        <img style={{ width: '60%' }} src={imageSrc} alt="apply" />
      </Box>
      <Grid pl={4} pr={4} mt={4} flexDirection="column" container>
        <Grid item>
          <Typography fontWeight={800} variant="h6">
            {headerText}
          </Typography>
        </Grid>
        <Grid mt={2} item>
          <Typography variant="body1">{bodyText}</Typography>
        </Grid>
        {peerState && (
          <Grid mt={6} justifyContent="space-between" alignItems="center" container item>
            <Grid item>
              <Typography fontSize="1.2rem" fontWeight={600} variant="body1">
                Status
              </Typography>
            </Grid>
            <Grid item>
              <Paper
                sx={{
                  bgcolor: statusOptions.bgcolor,
                  color: statusOptions.color,
                  borderRadius: 2,
                  px: 4,
                  py: 1,
                }}
              >
                <a
                  className={statusOptions.to ? 'cursor-pointer' : ''}
                  onClick={() => statusOptions.to && setActiveStep(statusOptions.to)}
                >
                  <Typography color="inherit" fontWeight={600} variant="h6">
                    {statusOptions.status}
                  </Typography>
                </a>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Box mt={3} />
      <Box mt="auto" />
    </>
  );
};

export default ApplicationPendingLayout;
